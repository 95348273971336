<template>
  <div>
    <h1>Meta Data</h1>
    <div v-for="(page, index) in pages" :key="index">
      <v-card class="mx-auto pa-5 my-3" max-width="500">
        <v-card-title>{{ page }}</v-card-title>
        <v-card-text>
          <v-text-field v-model="metaData[page].title" label="Title" outlined></v-text-field>
          <v-text-field v-model="metaData[page].description" label="Description" outlined></v-text-field>
          <v-text-field v-model="metaData[page].keywords" label="Keywords" outlined></v-text-field>
          <v-text-field v-model="metaData[page].robots" label="Robots" outlined></v-text-field>
          <v-text-field v-model="metaData[page].viewport" label="Viewport" outlined></v-text-field>
          <v-text-field v-model="metaData[page].canonical" label="Canonical" outlined></v-text-field>
          <v-text-field v-model="metaData[page].author" label="Author" outlined></v-text-field>
          <v-text-field v-model="metaData[page].referrer" label="Referrer" outlined></v-text-field>
        </v-card-text>
      </v-card>
    </div>
    <v-btn block color="primary" @click="saveMetaData">Save</v-btn>
  </div>
</template>

<script>
import fire from "@/main";

export default {
  name: 'MetaData',
  data() {
    return {
      pages: ['home', 'search', 'about', 'news'],
      metaData: {
        home: { title: '', description: '', keywords: '', robots: '', viewport: '', canonical: '', author: '', referrer: '' },
        search: { title: '', description: '', keywords: '', robots: '', viewport: '', canonical: '', author: '', referrer: '' },
        about: { title: '', description: '', keywords: '', robots: '', viewport: '', canonical: '', author: '', referrer: '' },
        news: { title: '', description: '', keywords: '', robots: '', viewport: '', canonical: '', author: '', referrer: '' },
      },
    };
  },
  created() {
    // Load metaData from Firebase Realtime Database
    fire.database().ref('admin/metaData').on('value', (snapshot) => {
      if (snapshot.val() === null) {
        fire.database().ref('admin/metaData').set(this.metaData);
      } else {
        this.metaData = snapshot.val();
      }

    });
  },
  methods: {
    saveMetaData() {
      // Save metaData to Firebase Realtime Database
      fire.database().ref('admin/metaData').set(this.metaData);
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
